export default {
  init() {
    $('#uno').on('click', function() {
      $('.nav-link').removeClass('active');
      $(this).addClass('active');
      if ($('#collapseOrder').hasClass('show')) {
        $('#ordering')[0].click();
      }
      if ($('#collapseMusic').hasClass('show')) {
        $('#music')[0].click();
      }
    });

    $('#dos').on('click', function() {
      $('.nav-link').removeClass('active');
      $(this).addClass('active');
      if ($('#collapseOrder').hasClass('show')) {
        $('#ordering')[0].click();
      }
      if ($('#collapseMusic').hasClass('show')) {
        $('#music')[0].click();
      }
    });

    $('#tres').on('click', function() {
      $('.nav-link').removeClass('active');
      $(this).addClass('active');
      if ($('#collapseOrder').hasClass('show')) {
        $('#ordering')[0].click();
      }
      if ($('#collapseMusic').hasClass('show')) {
        $('#music')[0].click();
      }
    });

    $('#ordering').on('click', function() {
      $('.nav-link').removeClass('active');
      $(this).addClass('active');
      if ($('#collapseMusic').hasClass('show')) {
        $('#music')[0].click();
      }
    });

    $('#music').on('click', function() {
      $('.nav-link').removeClass('active');
      $(this).addClass('active');
      if ($('#collapseOrder').hasClass('show')) {
        $('#ordering')[0].click();
      }
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
